<template>
  <ul class="menu-nav">
    <template v-for="menu in menus">
      <template v-if="!menu.submenus.length">
        <router-link
          :to="menu.rota"
          v-slot="{ href, navigate, isActive, isExactActive }"
          :key="menu.id"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i :class="['menu-icon', menu.icone]"></i>
              <span class="menu-text">{{ menu.nome }}</span>
            </a>
          </li>
        </router-link>
      </template>

      <template v-else>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/vue-bootstrap'),
          }"
          :key="menu.id"
        >
          <a href="#" class="menu-link menu-toggle">
            <i :class="['menu-icon', menu.icone]"></i>
            <span class="menu-text">{{ menu.nome }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text">{{ menu.nome }}</span>
                </span>
              </li>

              <router-link
                v-for="submenu in menu.submenus"
                :to="submenu.rota"
                :key="submenu.id"
                v-slot="{ href, navigate, isActive, isExactActive }"
                custom
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ submenu.nome }}</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {
    ...mapGetters(["menus"]),
  },
};
</script>
