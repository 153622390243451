<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Olá,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ usuarioAutenticado.nome }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="usuarioAutenticado.foto" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ usuarioAutenticado.nome.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">Perfil</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-50 mr-5">
            <img class="symbol-label" :src="usuarioAutenticado.foto" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/custom-pages/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ usuarioAutenticado.nome }}
            </router-link>
            <div class="text-muted mt-1">{{ usuarioAutenticado.usuario }}</div>
          </div>
        </div>

        <button
          class="btn btn-light-danger btn-bold btn-block mt-5"
          @click="desconectar"
        >
          Desconectar
        </button>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import AutenticacaoService from "@/core/services/autenticacao.service";
import { DADOS_AUTENTICACAO } from "@/core/services/store/autenticacao.module";
import Swal from "sweetalert2";

export default {
  name: "KTQuickUser",
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    desconectar() {
      Swal.fire({
        title: "Deseja desconectar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.desconectarUsuario();
        }
      });
    },

    desconectarUsuario() {
      AutenticacaoService.desconectar().then(() => {
        this.$store.dispatch(DADOS_AUTENTICACAO, null).then(() => {
          this.$router.push({ name: "autenticar" });
        });
      });
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters(["usuarioAutenticado"]),
  },
};
</script>
