var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.menus),function(menu){return [(!menu.submenus.length)?[_c('router-link',{key:menu.id,attrs:{"to":menu.rota,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:['menu-icon', menu.icone]}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.nome))])])])]}}],null,true)})]:[_c('li',{key:menu.id,staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{class:['menu-icon', menu.icone]}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.nome))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.nome))])])]),_vm._l((menu.submenus),function(submenu){return _c('router-link',{key:submenu.id,attrs:{"to":submenu.rota,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu.nome))])])])]}}],null,true)})})],2)])])]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }